import { Renderer2 } from '@angular/core';
import { ChatExtensionAction } from './action.interface';
import { ConversationModel } from '../models/conversation.model';

export class CitationAction implements ChatExtensionAction {
  constructor(private renderer: Renderer2) { }

  public canHandle(aiResponse: string): boolean {
    return aiResponse.includes('chatExtensionAction citation');
  }

  public handleAction(aiMessage: HTMLDivElement, conversation: ConversationModel): void {
    const buttons = aiMessage.querySelectorAll('.citation button') as NodeListOf<HTMLButtonElement>;
    buttons.forEach(button => {
      this.renderer.listen(button, 'click', () => {
        this.handleCitationToggle(aiMessage, conversation);
      });
    });
  }

  private handleCitationToggle(aiMessage: HTMLDivElement, conversation: ConversationModel): void {
    const citationContent = aiMessage.querySelector(".citationContent") as HTMLDivElement;
    if (citationContent.classList.contains('collapse')) {
      citationContent.classList.remove('collapse');
    }
    else {
      citationContent.classList.add('collapse');
    }
  }
}
