import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { ChatComponent } from './pages/chat/chat.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalBroadcastService, MsalGuard, MsalInterceptor, MsalModule, MsalRedirectComponent, MsalService } from '@azure/msal-angular';
import { environment } from '../environments/environment';
import { MSALGuardConfigFactory, MSALInstanceFactory, MSALInterceptorConfigFactory } from '../services/msal.service';
import { LayoutComponent } from './layout/layout/layout.component';
import { LogoComponent } from './components/logo/logo.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { SidebarComponent } from './components/chat/sidebar/sidebar.component';
import { NewchatComponent } from './components/chat/newchat/newchat.component';
import { TopicpaneComponent } from './components/chat/topicpane/topicpane.component';
import { StoreComponent } from './components/store/store.component';
import { MessagepaneComponent } from './components/chat/messagepane/messagepane.component';
import { ChatinputComponent } from './components/chat/chatinput/chatinput.component';
import { ConversationpaneComponent } from './components/chat/conversationpane/conversationpane.component';
import { LoadingScreenComponent } from './components/chat/loading-screen/loading-screen.component';
import { TopbarComponent } from './components/topbar/topbar.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ChatComponent,
    LayoutComponent,
    LogoComponent,
    UserProfileComponent,
    SidebarComponent,
    NewchatComponent,
    TopicpaneComponent,
    StoreComponent,
    MessagepaneComponent,
    ChatinputComponent,
    ConversationpaneComponent,
    TopbarComponent,
    LoadingScreenComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    MsalModule.forRoot(
      MSALInstanceFactory(),
      MSALGuardConfigFactory(),
      MSALInterceptorConfigFactory(),
    ),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
