<div class="chat-input-container">
  @if(showInvalidFileTypeAlert){
  <div class="alert alert-warning" role="alert">
    <b>Only PDF files are supported.</b>
    <button type="button" class="close" aria-label="Close" (click)="showInvalidFileTypeAlert = false"
      style="outline:none;border:none;background:none;float:right;">
      <i class="fas fa-times"></i>
    </button>
  </div>
  }

  @if(getFileModels().length > 0){
  <div class="file-display mb-2">
    <div class="row">
      @for(fileModel of getFileModels(); track fileModel){
      <div class="col-md-4 mb-2 fileContainer">
        <div class=" mb-1 d-flex align-items-center">
          <i class="fa-solid fa-file-pdf"></i>
          <span class="ms-2">{{fileModel.fileName}}</span>
          <i class="fa fa-times ms-2" (click)="removeFile(fileModel.fileName)" style="cursor: pointer;"
            title="Remove pdf file"></i>
          <!-- Close icon -->
        </div>
      </div>
      }
    </div>
  </div>
  }
  <div class="row">
    <div class="col-auto d-flex align-items-end mb-4">
      <input type="file" #fileInput accept="application/pdf" multiple style="display: none;"
        (change)="handleFileInput($event)" />
      <i class="fa fa-2xl fa-upload" (click)="fileInput.click()" title="Upload pdf files"></i>
    </div>
    <div class="col">
      <textarea #textarea class="form-control-lg mb-2 form-control px-4" rows="1"
        placeholder="How can I help you today?"
        (keydown)="onTextareaKeydown($event)">{{selectedChatOption?.description}}</textarea>
    </div>
    <div class="col-auto d-flex align-items-end mb-4">
      <button #sendButton class="btn-send-icon" (click)="sendMessage()"
        [disabled]="!textarea.value.trim() || isSendingMessage" aria-label="Send Message" title="Send message">
        <i class="fa fa-2xl fa-paper-plane"></i>
      </button>
    </div>
  </div>
</div>