import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-chat-loadingscreen',
  templateUrl: './loading-screen.component.html',
  styleUrl: './loading-screen.component.scss'
})
export class LoadingScreenComponent {
  @Input() loadingScreenTitle!: string;
}
