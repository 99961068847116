import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ChatOptionModel } from '../../../models/chatoption.model';
import { ChatService } from '../../../../services/chat.service';
import { ChatServiceModel } from '../../../models/chatservice.model';

@Component({
  selector: 'app-chat-messagepane',
  templateUrl: './messagepane.component.html',
  styleUrl: './messagepane.component.scss'
})
export class MessagepaneComponent {
  chatOptions: ChatOptionModel[] = [];

  @Input() chatServiceModel!: ChatServiceModel;
  @Output() chatOptionSelected = new EventEmitter<ChatOptionModel>();
  constructor(private chatService: ChatService) {}

  ngOnInit(): void {
    this.getChatOptions(this.chatServiceModel.url);
  }

  selectChatOption(option: ChatOptionModel) {
    this.chatOptionSelected.emit(option);
  }

  getChatOptions(serviceName: string): void {
    this.chatService.getChatOptions(serviceName).subscribe((data: ChatOptionModel[]) => {
      this.chatOptions = data;
    }, error => {
      console.error('There was an error!', error);
    });
  }
}
