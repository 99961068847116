<!-- <p>Chat type</p>
<p>{{botType}}</p> -->
<div class="d-flex justify-content-center h-100">
    <div class="container-fluid">
        <div class="row">
            <div class="col d-flex flex-column chat-wrapper">
                @if(chatServiceModel){
                @if(!topicId){
                <app-chat-messagepane [chatServiceModel]="chatServiceModel"
                    (chatOptionSelected)="onChatOptionSelected($event)" class="flex-grow-1 mt-4" />
                }@else {
                <app-chat-conversationpane [conversations]="conversations" [loadingConversations]="loadingConversations"
                    class="flex-grow-1 mt-4 mb-2" />
                }
                <app-chat-chatinput [selectedChatOption]="selectedChatOption" [isSendingMessage]="isSendingMessage"
                    [topic]="topic" (uploadTopicFiles)="uploadTopicFiles($event)"
                    (removeTopicFile)="removeTopicFile($event)" class="flex-shrink-0" />
                }
            </div>
        </div>
    </div>
</div>