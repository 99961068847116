import { Component, OnInit } from '@angular/core';
import { ChatService } from '../../../services/chat.service';
import { ChatServiceModel } from '../../models/chatservice.model';

@Component({
  selector: 'app-store',
  templateUrl: './store.component.html',
  styleUrls: ['./store.component.scss']
})
export class StoreComponent implements OnInit {
  chatServices: ChatServiceModel[] = [];
  filteredChatServices: ChatServiceModel[] = [];

  constructor(private chatService: ChatService) {}

  ngOnInit(): void {
    this.chatService.getChatServices().subscribe({
      next: (services) => {
        this.chatServices = services;
        this.filteredChatServices = services;
      },
      error: (err) => {
        console.error('Error fetching chat services:', err);
      }
    });
  }

  onSearch(event: Event): void {
    const searchTerm = (event.target as HTMLInputElement).value.toLowerCase();

    this.filteredChatServices = this.chatServices.filter(service =>
      service.title.toLowerCase().includes(searchTerm) || service.description.toLowerCase().includes(searchTerm)
    );
  }

  redirectTo(url: string): void {
    window.location.href = `/${url}`;
  }
}
