import { Component, HostListener, OnInit } from '@angular/core';
@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.scss'
})
export class LayoutComponent implements OnInit {
  isSidebarVisible: boolean = false;

  constructor() { }

  ngOnInit() {
  }
  getWindowWidth(): number {
    return window.innerWidth;
  }
  isMobileDevice(): boolean {
    return !(this.getWindowWidth() > 768);
  }

  public toggleSidebar() {
    this.isSidebarVisible = !this.isSidebarVisible;
  }
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const targetElement = event.target as HTMLElement;

    // Check if the click is inside the sidebar or on the toggle button
    const clickedInsideSidebar = targetElement.closest('#sidebar') !== null;
    const clickedToggle = targetElement.closest('#sidebarToggle') !== null;

    if (!clickedInsideSidebar && !clickedToggle && this.isSidebarVisible && this.isMobileDevice()) {
      this.toggleSidebar();
    }
  }

}
