import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UserModel } from '../../models/user.model';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrl: './topbar.component.scss'
})
export class TopbarComponent {
  user: UserModel | undefined;
  @Output() toggleSidebar: EventEmitter<void> = new EventEmitter();
  @Input() isMobileDevice!: boolean;
  enableRfpDesk: Boolean = false;
  onToggleSidebar() {
    this.toggleSidebar.emit();
  }

  constructor(private userService: UserService) { }

  ngOnInit(): void {
    this.userService.getUserProfile().subscribe({
      next: (userData: UserModel) => {
        this.user = userData;
        if (this.user.groups) {
          this.enableRfpDesk = this.user.groups.some(group => group.value === 'rfp_desk_beta');
        }
      },
      error: (err: any) => {
        console.error('Error fetching user data:', err);
      },
    });

  }
}
