import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnChanges, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { ConversationModel } from '../../../models/conversation.model';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ChatExtensionsService } from '../../../../services/chat-extensions.service';
import markdownit from 'markdown-it'
@Component({
  selector: 'app-chat-conversationpane',
  templateUrl: './conversationpane.component.html',
  styleUrl: './conversationpane.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConversationpaneComponent implements AfterViewInit {
  constructor(private sanitizer: DomSanitizer, private renderer: Renderer2, private chatExtensionsService: ChatExtensionsService, private changeRef: ChangeDetectorRef) { }
  @Input() conversations!: ConversationModel[];
  @Input() loadingConversations!: Boolean;
  @ViewChildren('lastAI') lastAIMessages!: QueryList<ElementRef>;
  @ViewChildren('responseCompleted') responseCompleted!: QueryList<ElementRef>;
  @ViewChild('hiddenCopyContainer', { static: true }) hiddenCopyContainer!: ElementRef;
  md = markdownit({ html: true });
  // ngOnInit() {
  //   window.toggleElementCss = this.toggleElementCss.bind(this);
  // }

  // ngOnDestroy() {
  //   delete window.toggleElementCss;
  // }




  ngAfterViewInit() {

    this.lastAIMessages.changes.subscribe((list: QueryList<ElementRef>) => {
      if (this.conversations.length > 1) {
        const lastAI = list.last;
        if (lastAI) {
          lastAI.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
      }
    });
  }

  setUnsafeHtml(aiMessage: HTMLDivElement, conversation: ConversationModel): SafeHtml {

    const aiMessageContainer = aiMessage.querySelector(".chatMessageContainer") as HTMLDivElement;
    //sometimes is it just text rendered from gpt that needs to keep it's spacing and it is not html
    if (!conversation.aiResponse!.includes("<") && !conversation.aiResponse!.includes(">") && aiMessageContainer && aiMessageContainer.childNodes.length === 1 && aiMessageContainer.childNodes[0].nodeType === Node.TEXT_NODE) {
      aiMessageContainer.classList.add('only-text');
    }
    return this.sanitizer.bypassSecurityTrustHtml(this.md.render(conversation.aiResponse!));
  }
  handleResponseCompleted(aiMessage: HTMLDivElement, conversation: ConversationModel): Boolean {
    //pass list of supported actions by service to make it more optimal
    //also only do this when the reponse is completed
    if (conversation.isResponseComplete == true) {
      // setTimeout(() => {
      this.chatExtensionsService.processActions(aiMessage, conversation);
      // }, 100);
    }
    return conversation.isResponseComplete!;
  }

  copyChatMessage(chatMessageElement: HTMLDivElement): void {
    const innerHtmlContent = chatMessageElement.querySelector('.chatMessageContainer')!.innerHTML;
    const copyContainer: HTMLElement = this.hiddenCopyContainer.nativeElement;
    copyContainer.innerHTML = innerHtmlContent;
    this.selectContentAndCopy(copyContainer);
  }

  private selectContentAndCopy(element: HTMLElement): void {
    const range = document.createRange();
    const selection = window.getSelection()!;
    range.selectNodeContents(element);
    selection.removeAllRanges();
    selection.addRange(range);

    try {
      document.execCommand('copy');
      selection.removeAllRanges();

    } catch (err) {
      console.error('Failed to copy content', err);
    }
  }
}
