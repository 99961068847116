// AuthenticatedClient.ts
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { Observable, throwError, mergeMap, catchError, of, map, firstValueFrom } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthenticatedClient {
  constructor(private http: HttpClient, private msalService: MsalService) { }

  private getHeaders(): Observable<HttpHeaders> {
    return this.msalService.acquireTokenSilent({
      scopes: ['api://' + environment.azureAd.clientId + '/ngpt'],
      account: this.msalService.instance.getAllAccounts()[0],
    }).pipe(
      mergeMap(tokenResponse => {
        const headers = new HttpHeaders({
          'X-MS-AUTH-TOKEN': tokenResponse.accessToken,
        });
        return of(headers);
      }),
      catchError(err => {
        console.error(err);
        return throwError(err);
      })
    );
  }
  private headersToObject(headers: HttpHeaders): { [header: string]: string | string[] | null } {
    let headersObj: { [header: string]: string | string[] | null } = {};
    headers.keys().forEach(key => {
      headersObj[key] = headers.getAll(key);
    });
    return headersObj;
  }


  async getRawHeaders(): Promise<{ [header: string]: string | string[] | null }> {
    const headers = await firstValueFrom(this.getHeaders());
    return this.headersToObject(headers);
  }
  get<T>(url: string): Observable<T> {
    return this.getHeaders().pipe(
      mergeMap(headers => {
        return this.http.get<T>(url, { headers });
      })
    );
  }
  delete<T>(url: string): Observable<T> {
    return this.getHeaders().pipe(
      mergeMap(headers => {
        return this.http.delete<T>(url, { headers });
      })
    );
  }

  post<T>(url: string, body: any): Observable<T> {
    return this.getHeaders().pipe(
      mergeMap(headers => {
        return this.http.post<T>(url, body, { headers });
      }),
      catchError(err => {
        console.error(err);
        return throwError(err);
      })
    );
  }

  put<T>(url: string, body: any): Observable<T> {
    return this.getHeaders().pipe(
      mergeMap(headers => {
        return this.http.put<T>(url, body, { headers });
      }),
      catchError(err => {
        console.error(err);
        return throwError(err);
      })
    );
  }



  postFormData<T>(url: string, body: FormData): Observable<T> {
    return this.getHeaders().pipe(
      mergeMap(headers => {
        headers = headers.delete('Content-Type');
        return this.http.post<T>(url, body, { headers });
      }),
      catchError(err => {
        console.error(err);
        return throwError(err);
      })
    );
  }
}
