import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { environment } from '../environments/environment';
import { UserModel } from '../app/models/user.model';
import { AuthenticatedClient } from '../core/AuthenticatedClient';
@Injectable({
    providedIn: 'root',
  })
  export class UserService {
    private apiUrl = `${environment.apiUrl}/user`;
  
    constructor(private authClient: AuthenticatedClient) {}
  
    getUserProfile(): Observable<UserModel> {
      return this.authClient.get<UserModel>(this.apiUrl);
    }
  }
  