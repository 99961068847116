import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({ providedIn: 'root' })
export class AppStateService {
  private appStateSubject: BehaviorSubject<AppState>; 

  get appState() {
    return this.appStateSubject.asObservable();
  }

  constructor() {
    const appState: AppState = {
      serviceName: null,
    };
    this.appStateSubject = new BehaviorSubject<AppState>(appState);
  }

  setCurrentServiceName(serviceName: string) {
    this.appStateSubject.next({ ...this.appState, serviceName: serviceName });
  }
}

export interface AppState {
  serviceName: string | null;
}