import { Renderer2 } from '@angular/core';
import { ChatExtensionAction } from './action.interface';
import { ConversationModel } from '../models/conversation.model';

export class SubmitCodeJSON implements ChatExtensionAction {
  constructor(private renderer: Renderer2) { }

  public canHandle(aiResponse: string): boolean {
    return aiResponse.includes('chatExtensionAction submitCodeJSON');
  }

  public handleAction(aiMessage: HTMLDivElement, conversation: ConversationModel): void {

    const buttons = aiMessage.querySelectorAll('.submitCodeJSON button') as NodeListOf<HTMLButtonElement>;
    buttons.forEach(button => {
      this.renderer.listen(button, 'click', () => {
        const postDataUrl = button.attributes.getNamedItem('api-path');
        const jsonDataCodeId = button.attributes.getNamedItem('codejsonid');
        const jsonData = aiMessage.querySelector("pre"); //aiMessage.querySelector("#" + jsonDataCodeId?.value ?? "");
        var cleanJsonString = jsonData?.innerText.replace(/\s+/g, ' ').trim();
        console.log("Post to url:" + postDataUrl?.value);
        console.log(JSON.parse(cleanJsonString ?? ""));
        //this.submitData(conversation);
      });
    });
  }

  private submitData(aiMessage: HTMLDivElement, conversation: ConversationModel): void {
    //console.log()
  }
}
