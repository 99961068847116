import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ChatService } from '../../../services/chat.service';
import { ChatServiceModel } from '../../models/chatservice.model';
import { ChatOptionModel } from '../../models/chatoption.model';
import { Location } from '@angular/common';
import { TopicModel } from '../../models/topic.model';
import { QueryPrompt } from '../../models/queryprompt.model';
import { TopicService } from '../../../services/topic.service';
import { TopicConversationModel } from '../../models/topicconversation.model';
import { ConversationModel } from '../../models/conversation.model';
import { Subscription, firstValueFrom } from 'rxjs';
import { AddConversationModel } from '../../models/addconversation.model';
import { AppStateService } from '../../../services/app-state.service';
@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrl: './chat.component.scss'
})
export class ChatComponent {
  loadingConversations: boolean = false;
  topic!: TopicModel;
  serviceName = '';
  topicId = '';
  activeChatTopicId = '';
  chatServiceModel!: ChatServiceModel;
  selectedChatOption: ChatOptionModel | null = null;
  chatHistory!: TopicConversationModel[];
  conversations: ConversationModel[] = [];
  currentUserPrompt!: string;
  routeSub!: Subscription;
  isSendingMessage: boolean = false;
  constructor(private route: ActivatedRoute,
    private chatService: ChatService,
    private topicService: TopicService,
    private appState: AppStateService,
    private location: Location) { }

  ngOnInit(): void {

    this.routeSub = this.route.params.subscribe(params => {
      this.serviceName = params['chat-service'];
      this.topicId = params['topic-id'];

      this.getChatService(this.serviceName);

      if (this.topicId) {
        this.getTopic(this.topicId);
        this.getTopicConversations(this.topicId);
      }
    });

    this.chatService.conversationCompleted.subscribe(async (conversationCompleted: ConversationModel) => {
      if (conversationCompleted.isResponseComplete && this.topicId) {
        const addConversation: AddConversationModel = {
          question: conversationCompleted.userMessage,
          response: conversationCompleted.aiResponse!,
          topicId: this.topicId
        };
        await firstValueFrom(this.chatService.addConversation(addConversation));
        this.isSendingMessage = false;
      }
    });

    this.chatService.conversationInProgress.subscribe((conversation) => {
      if (!conversation.isLoading && this.activeChatTopicId == this.topicId) {
        const existingConversations = this.conversations;
        existingConversations.pop();
        this.conversations = [...existingConversations, conversation];
      }
    })
  }

  ngOnDestroy(): void {
    this.routeSub.unsubscribe();
  }

  getTopic(topicId: string): void {
    this.topicService.getTopic(topicId).subscribe({
      next: (data: TopicModel) => {
        this.topic = data;
        this.topicService.emitSelectTopic(this.topic);
      },
      error: error => {
        console.error('There was an error!', error);
      }
    });
  }

  getChatService(serviceName: string): void {
    this.chatService.getChatService(serviceName).subscribe({
      next: (data: ChatServiceModel) => {
        this.chatServiceModel = data;
        this.appState.setCurrentServiceName(this.serviceName);
      },
      error: error => {
        console.error('There was an error!', error);
      }
    });
  }

  getTopicConversations(topicId: string): void {
    this.loadingConversations = true;
    if (this.conversations) {
      this.conversations.splice(0, this.conversations.length);
    }
    this.topicService.getTopicConversations(topicId).subscribe({
      next: (data: TopicConversationModel[]) => {
        this.chatHistory = data;
        this.conversations = this.mapToConversationModel(this.chatHistory);
        this.loadingConversations = false;
      },
      error: error => {
        console.error('There was an error!', error);
      }
    });
  }

  onChatOptionSelected(chatOption: ChatOptionModel) {
    this.selectedChatOption = chatOption;
  }

  async removeTopicFile(fileName: string) {
    var res = await firstValueFrom(this.chatService.deleteChatFile(this.topicId, fileName));
  }


  async uploadTopicFiles(event: { message: string; files: File[] }) {
    this.isSendingMessage = true;

    const { message, files } = event;
    this.currentUserPrompt = message;
    this.selectedChatOption = null;

    const newConversation: ConversationModel = {
      id: crypto.randomUUID(),
      userMessage: message,
      aiResponse: undefined,
      isLoading: true,
    };

    this.conversations = [...this.conversations, newConversation];

    if (!this.topicId) {
      await this.startChat(message);
    }


    if (files && files.length > 0) {
      const fileuploadRes = await this.chatService.uploadChatFiles(this.topicId, files);
    }


    const queryPrompt: QueryPrompt = {
      Prompt: message,
      TopicId: this.topicId
    };
    this.activeChatTopicId = this.topicId;
    this.chatService.chatResponseStream(queryPrompt, this.serviceName, newConversation);

    // console.log(this.currentUserPrompt);
  }
  mapToConversationModel(topics: TopicConversationModel[]): ConversationModel[] {
    return topics.map(topic => ({
      id: topic.conversationId,
      userMessage: topic.question,
      aiResponse: topic.response,
      isLoading: false,
      isResponseComplete: true,
    }));
  };

  async startChat(message: string) {
    let queryPrompt: QueryPrompt = { Prompt: message };

    try {
      const data: TopicModel = await firstValueFrom(this.chatService.startChat(queryPrompt, this.serviceName));
      this.topic = data;
      this.topicId = this.topic.topicID;
      const currentPath = this.location.path();
      const topicPath = `${currentPath}/${this.topic.topicID}`;
      this.location.replaceState(topicPath);

      this.topicService.emitNewTopic(this.topic);
    } catch (error) {
      console.error('There was an error!', error);
    }
  }


}
