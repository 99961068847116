<div class="d-flex chat-container">
    @if(loadingConversations){
    <div class="row my-auto chatMessage">
        <app-chat-loadingscreen loadingScreenTitle="Chat History" />
    </div>
    }
    @else{
    @for(conversation of conversations; track conversation.id){
    <div class="chatMessage userMessage">
        <i class="fa fa-user faUser"></i>
        <div class="chatMessageContainer">{{ conversation.userMessage }}</div>
    </div>
    @if(conversation.isLoading){
    <div class="chatMessage aiMessage" #lastAI>
        <i class="fa fa-robot faRobot"></i>
        <div class="spinnerContainer">
            <span class="spinner-grow text-danger" role="status"></span>
            <span class="spinner-grow text-danger" role="status"></span>
            <span class="spinner-grow text-danger" role="status"></span>
        </div>
    </div>
    }
    @else{
    <div class="chatMessage aiMessage" #lastAI>
        <i class="fa fa-robot faRobot"></i>
        <div class="chatMessageContainer" [innerHTML]="setUnsafeHtml(lastAI,conversation)"></div>
        <i class="fa fa-copy faCopy" (click)="copyChatMessage(lastAI)" title="Copy"></i>
    </div>
    @if(handleResponseCompleted(lastAI,conversation)){
    <hr class="chat-hr" #responseCompleted />
    }
    }

    }
    }
</div>
<div #hiddenCopyContainer contenteditable="true" style="position: absolute; left: -9999px;"></div>