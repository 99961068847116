import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { ChatComponent } from './pages/chat/chat.component';
import { MsalGuard } from '@azure/msal-angular';
import { LayoutComponent } from './layout/layout/layout.component';

const routes: Routes = [
  // { path: '', pathMatch: 'full', redirectTo: 'home' },
  // { path: 'home', component: HomeComponent, canActivate: [MsalGuard] },
  // { path: 'chat/:bot-type', component: ChatComponent, canActivate: [MsalGuard] }

  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'chat'
      },
      {
        path: 'home',
        component: HomeComponent,
        canActivate: [MsalGuard]
      },
      {
        path: ':chat-service',
        component: ChatComponent,
        canActivate: [MsalGuard]
      },
      {
        path: ':chat-service/:topic-id',
        component: ChatComponent,
        canActivate: [MsalGuard]
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
