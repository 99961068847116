import { Component, OnInit, HostListener, ElementRef, ViewChild, QueryList, Renderer2 } from '@angular/core';
import { TopicModel } from '../../../models/topic.model';
import { mergeMap, from, groupBy, toArray, Subscription, firstValueFrom } from 'rxjs';
import { TopicService } from '../../../../services/topic.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-chat-topicpane',
  templateUrl: './topicpane.component.html',
  styleUrl: './topicpane.component.scss'
})
export class TopicpaneComponent implements OnInit {
  groupedTopics: { timeFrame: string, topics: TopicModel[] }[] = [];
  selectedTopic: TopicModel | null = null;
  selectedTopicId = "";
  editableTopic: TopicModel | null = null;
  private newTopicSubscription!: Subscription;
  private selectTopicSubscription!: Subscription;
  overlayPosition = { x: 0, y: 0 };
  @ViewChild('menuOverlay') menuOverlay!: ElementRef;
  private globalClickListener: Function | null = null;
  constructor(private topicService: TopicService, private eRef: ElementRef, private router: Router, private renderer: Renderer2) { }

  ngOnInit(): void {

    this.newTopicSubscription = this.topicService.newTopicEmitter.subscribe(newTopic => {
      if (newTopic) {
        this.handleNewTopic(newTopic);
      }
    });


    this.selectTopicSubscription = this.topicService.selectTopicEmitter.subscribe(topic => {
      if (topic) {
        //this.selectedTopic = topic;
        this.selectedTopicId = topic.topicID;
      }
    });

    this.topicService.getTopics().pipe(
      mergeMap(topics => from(topics)),
      groupBy(topic => topic.timeFrame),
      mergeMap(group => group.pipe(toArray(), mergeMap(topics => from([{ timeFrame: group.key, topics }])))),
      toArray()
    ).subscribe(groupedTopics => {
      this.groupedTopics = groupedTopics;
    });
  }
  handleNewTopic(newTopic: TopicModel): void {
    let group = this.groupedTopics.find(g => g.timeFrame === newTopic.timeFrame);

    if (group) {

      group.topics.unshift(newTopic);
    } else {

      this.groupedTopics.unshift({
        timeFrame: newTopic.timeFrame,
        topics: [newTopic]
      });
    }

    this.selectTopic(newTopic, true);
  }

  selectTopic(topic: TopicModel, isNewTopic: boolean = false): void {
    if (this.selectedTopicId != topic.topicID) {
      this.selectedTopicId = topic.topicID;
      if (!isNewTopic) {
        this.router.navigate([`/${topic.serviceName}`, topic.topicID]);
      }
    }
  }
  openMenu(event: MouseEvent, topic: TopicModel): void {
    event.preventDefault();
    event.stopPropagation();
    this.selectedTopic = topic;
    this.overlayPosition = { x: event.clientX, y: event.clientY };
    this.attachGlobalClickListener();
  }
  private attachGlobalClickListener(): void {
    if (!this.globalClickListener) {
      this.globalClickListener = this.renderer.listen('document', 'click', this.onDocumentClick.bind(this));
    }
  }

  onDocumentClick(event: MouseEvent): void {
    if (this.selectedTopicId) {
      if (!this.menuOverlay || !this.menuOverlay.nativeElement.contains(event.target)) {
        event.preventDefault();
        event.stopPropagation();
        this.closeMenu();
      }
    }
  }

  private detachGlobalClickListener(): void {
    if (this.globalClickListener) {
      this.globalClickListener();
      this.globalClickListener = null;
    }
  }


  closeMenu(): void {
    this.selectedTopic = null;
    this.detachGlobalClickListener();
  }


  async deleteTopic(topic: TopicModel): Promise<void> {
    await firstValueFrom(this.topicService.deleteTopic(topic.topicID));
    this.closeMenu();
    this.router.navigateByUrl('/chat');
  }

  shareTopic(topic: TopicModel): void {
    // Implement the logic to share a topic
  }

  renameTopic(event: MouseEvent, topic: TopicModel): void {
    event.stopPropagation();
    this.editableTopic = topic;
    setTimeout(() => {
      setTimeout(() => {
        const editableElement = document.querySelector(`[data-editable-id="${topic.topicID}"]`) as HTMLSpanElement;
        if (editableElement) editableElement.focus();
      }, 0);
      this.closeMenu();
    }, 0);
  }

  saveTopic(topic: TopicModel, element: EventTarget | null): void {
    if (element && element as HTMLElement) {
      topic.topic = (element as HTMLElement).innerText;
      this.editableTopic = null;
      firstValueFrom(this.topicService.updateTopic(topic));
    }
  }
}