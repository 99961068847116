import { EventEmitter, Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { TopicModel } from '../app/models/topic.model';
import { AuthenticatedClient } from '../core/AuthenticatedClient';
import { Observable } from 'rxjs';
import { TopicConversationModel } from '../app/models/topicconversation.model';
import { BasicApiResponse } from '../app/models/basicresponse.model';
@Injectable({
  providedIn: 'root',
})
export class TopicService {
  private apiUrl = `${environment.apiUrl}`;
  newTopicEmitter = new EventEmitter<TopicModel>();
  selectTopicEmitter = new EventEmitter<TopicModel>();
  constructor(private authClient: AuthenticatedClient) { }

  getTopics(): Observable<TopicModel[]> {
    return this.authClient.get<TopicModel[]>(`${this.apiUrl}/usertopics`);
  }

  getTopic(topicId: string): Observable<TopicModel> {
    return this.authClient.get<TopicModel>(`${this.apiUrl}/topic/${topicId}`);
  }

  deleteTopic(topicId: string): Observable<BasicApiResponse> {
    return this.authClient.delete<BasicApiResponse>(`${this.apiUrl}/topic/${topicId}`);
  }

  updateTopic(topic: TopicModel): Observable<BasicApiResponse> {
    return this.authClient.put<BasicApiResponse>(`${this.apiUrl}/topic`, topic);
  }


  getTopicConversations(topicId: string): Observable<TopicConversationModel[]> {
    return this.authClient.get<TopicConversationModel[]>(`${this.apiUrl}/topicconversations/${topicId}`);
  }

  emitNewTopic(topic: TopicModel) {
    this.newTopicEmitter.emit(topic);
  }

  emitSelectTopic(topic: TopicModel) {
    this.selectTopicEmitter.emit(topic);
  }
}
