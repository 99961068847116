<div class="d-flex align-items-center justify-content-center chat-container">
    <div class="row my-auto">
        @if(!chatOptions.length){
        <app-chat-loadingscreen [loadingScreenTitle]="chatServiceModel.title" />
        }
        @else{
        <div class="text-center">
            <div>
                <div
                    class="gizmo-shadow-stroke relative flex h-full items-center justify-center rounded-full bg-white text-black">
                    <div class="row  align-items-end justify-content-center w-100">
                        <div class=" mb-2 col-auto"><img class="logoImage"
                                src="/assets/images/lgo-nmrk-n-reversed.svg" /></div>
                        <div class="col-auto">
                            <h2>
                                <strong>NEWMARKAI</strong>
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
            <h2 class="mt-4">How can I help you today?</h2>
        </div>
        }
    </div>
    <div class="row chat-options-row">
        @if(chatOptions){
        @for(chatOption of chatOptions; track chatOption){
        <div class="col-md-6 col-sm-1">
            <div class="card" (click)="selectChatOption(chatOption)">
                <div class="card-body">
                    <h6 class="card-title">{{ chatOption.title }}</h6>
                    <p class="card-text">{{ chatOption.description }}</p>
                </div>
            </div>
        </div>
        }
        }
    </div>
</div>