<div class="topic-history">
  @for (group of groupedTopics; track group) {
  <div>
    <h6 class="font-weight-bold text-secondary mt-4 timeline-header">{{ group.timeFrame }}</h6>
    <ul class="list-group">
      @for (topic of group.topics; track topic) {
      <li class="list-group-item d-flex justify-content-between align-items-center list-item"
        [class.selected]="selectedTopicId === topic.topicID" (click)="selectTopic(topic)">
        <span [attr.contenteditable]="editableTopic && editableTopic.topicID === topic.topicID ? 'true' : 'false'"
          [attr.data-editable-id]="topic.topicID" class="btn topic-name" (blur)="saveTopic(topic, $event.target)">{{
          topic.topic }}</span>
        @if(selectedTopicId === topic.topicID){
        <button class="btn" (click)="openMenu($event, topic)">
          <i class="fas fa-ellipsis-v"></i>
        </button>
        }
      </li>
      }
    </ul>
  </div>
  }
  @if(selectedTopic){
  <div #menuOverlay class="menu-overlay" [class.active]="selectedTopic" (click)="$event.stopPropagation()"
    [ngStyle]="{'top.px': overlayPosition.y, 'left.px': overlayPosition.x}">
    <ul class="list-group">
      <!-- <li class="list-group-item" (click)="shareTopic(selectedTopic)">
        <i class="fas fa-share-alt"></i> Share
      </li> -->
      <li class="list-group-item" (click)="renameTopic($event,selectedTopic)">
        <i class="fas fa-edit"></i> Rename
      </li>
      <li class="list-group-item" (click)="deleteTopic(selectedTopic)">
        <i class="fas fa-trash-alt text-danger"></i> Delete
      </li>
    </ul>
  </div>
  }
</div>