import { ChangeDetectorRef, Injectable, NgZone, Renderer2, RendererFactory2 } from '@angular/core';
import { ConversationModel } from '../app/models/conversation.model';
import { ChatExtensionAction } from '../app/actions/action.interface';
import { CitationAction } from '../app/actions/citation.action';
import { SubmitCodeJSON } from '../app/actions/submitCodeJSON.action';

@Injectable({
  providedIn: 'root',
})
export class ChatExtensionsService {
  private renderer: Renderer2;
  private actionMap: { [key: string]: { new(renderer: Renderer2): ChatExtensionAction } } = {
    citation: CitationAction,
    submitCodeJSON: SubmitCodeJSON
  };

  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  public processActions(aiMessage: HTMLDivElement, conversation: ConversationModel): void {
    Object.entries(this.actionMap).forEach(([actionKey, ActionClass]) => {
      const actionInstance: ChatExtensionAction = new ActionClass(this.renderer);
      if (actionInstance.canHandle(conversation.aiResponse!)) {
        actionInstance.handleAction(aiMessage, conversation);
      }
    });
  }
}
