import { EventEmitter, Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { AuthenticatedClient } from '../core/AuthenticatedClient';
import { Observable, firstValueFrom } from 'rxjs';
import { ChatServiceModel } from '../app/models/chatservice.model';
import { ChatOptionModel } from '../app/models/chatoption.model';
import { QueryPrompt } from '../app/models/queryprompt.model';
import { TopicModel } from '../app/models/topic.model';
import { ConversationModel } from '../app/models/conversation.model';
import { FileUploadResponse } from '../app/models/fileupload.model';
import { AddConversationModel } from '../app/models/addconversation.model';
import { TopicConversationModel } from '../app/models/topicconversation.model';
import { BasicApiResponse } from '../app/models/basicresponse.model';
@Injectable({
  providedIn: 'root',
})
export class ChatService {
  private apiUrl = `${environment.apiUrl}`;
  public conversationCompleted: EventEmitter<ConversationModel> = new EventEmitter();
  public conversationInProgress: EventEmitter<ConversationModel> = new EventEmitter();

  constructor(private authClient: AuthenticatedClient) { }

  startChat(queryPrompt: QueryPrompt, serviceName: string): Observable<TopicModel> {
    return this.authClient.post<TopicModel>(`${this.apiUrl}/startchat/${serviceName}`, queryPrompt);
  }

  addConversation(conversation: AddConversationModel): Observable<TopicConversationModel> {
    return this.authClient.post<TopicConversationModel>(`${this.apiUrl}/topicconversation`, conversation);
  }


  async chatResponseStream(queryPrompt: QueryPrompt, serviceName: string, conversationModel: ConversationModel): Promise<void> {

    const headers = await this.authClient.getRawHeaders();
    headers['Content-Type'] = 'application/json';
    headers['Authorization'] = `Bearer ${headers['X-MS-AUTH-TOKEN']}`;

    const stream = window.oboe({
      url: `${this.apiUrl}/chatasync/${serviceName}`,
      method: 'POST',
      headers: headers,
      body: JSON.stringify(queryPrompt)
    });
    let currentContent = "";
    //let timeoutIds: NodeJS.Timeout[] = [];
    stream.node('!.*', (word: string) => { // Receive words directly from the server
      // Schedule each word to be added to the content with a delay

      //const timeoutId = setTimeout(() => {
      currentContent += word; // Add the word and a space
      conversationModel.aiResponse = currentContent;
      conversationModel.isLoading = false;
      this.conversationInProgress.emit(conversationModel);

      //}, 100);
      //timeoutIds.push(timeoutId);
    }).done(() => {

      conversationModel.isResponseComplete = true;
      this.conversationCompleted.emit(conversationModel);

      stream.abort();
    });

  }

  async uploadChatFiles(topicId: string, files: File[]): Promise<FileUploadResponse> {
    const formData = new FormData();
    formData.append(`topicId`, topicId);
    files.forEach((file, index) => {
      formData.append(`files`, file);
    });

    const res = await firstValueFrom(this.authClient.postFormData<FileUploadResponse>(`${this.apiUrl}/chatfile`, formData));

    return res;
  }

  deleteChatFile(topicId: string, fileName: string): Observable<BasicApiResponse> {
    return this.authClient.delete<BasicApiResponse>(`${this.apiUrl}/chatfile/${topicId}/${fileName}`);
  }

  getChatServices(): Observable<ChatServiceModel[]> {
    return this.authClient.get<ChatServiceModel[]>(`${this.apiUrl}/chatservices`);
  }

  getChatService(serviceName: string): Observable<ChatServiceModel> {
    return this.authClient.get<ChatServiceModel>(`${this.apiUrl}/chatservice/${serviceName}`);
  }

  getChatOptions(serviceName: string): Observable<ChatOptionModel[]> {
    return this.authClient.get<ChatOptionModel[]>(`${this.apiUrl}/chatoptions/${serviceName}`);
  }

}
