<div class="container py-5">
            <div class="input-group">
                <input type="text" placeholder="Explore tailored AI powered Newmark Apps..." class="form-control form-control-lg rounded-pill mb-4" value="" (input)="onSearch($event)">
            </div>
    
            <div class="row mt-4">
                @for(service of filteredChatServices; track service ){
                  <div class="col-lg-3 col-md-6 mb-4">
                    <div class="card card-component" (click)="redirectTo(service.url)">
                      <div class="card-body">
                        <h5 class="card-title">{{ service.title }} <i class="fa-brands fa-bots float-end"></i></h5>
                        <hr class="card-hr" />
                        <span class="card-text">{{ service.description }}</span>
                        <!-- <i class="fa fa-paper-plane"></i>  -->
                        <div>
                        <button type="button" class="btn btn-light">Start Chat</button>
                        <!-- <i class="fa fa-paper-plane"></i> -->
                        </div>
                      </div>
                    </div>
                  </div>
            }
              </div>
    
    </div>
    