<div class="text-center">
    <div>
        <div
            class="gizmo-shadow-stroke relative flex h-full items-center justify-center rounded-full bg-white text-black">


            <div class="row  align-items-end justify-content-center w-100">
                <div class=" mb-2 col-auto">
                    <img class="logoImage rotate" src="/assets/images/lgo-nmrk-n-reversed.svg" />
                </div>
                <div class="col-auto">
                    <h2>
                        <strong>NEWMARKAI</strong>
                    </h2>
                </div>
            </div>
        </div>
    </div>
    <h2 class="mt-4">Loading {{loadingScreenTitle}}...</h2>
</div>