@if(isMobileDevice){
<app-logo />
}
<div class="d-flex justify-content-end mt-2 me-2">
    @if(enableRfpDesk){
    <a href="https://app.tennr.com/run" target="_blank" class="custom-fa-link" title="RFP Desk">
        <img src="../../assets/images/ico-rfp-00-64x64.svg" class="custom-fa-icon" alt="Custom Icon">
    </a>
    }


    <a href="https://teams.microsoft.com/l/channel/19%3a2ae0bfd81c684ab690839b833093c8cd%40thread.tacv2/Feedback%252C%2520Bugs%2520and%2520Enhancement%2520Requests?groupId=1c6d4f4a-7970-4130-9b3c-df0ccffca13f&tenantId=b4c85df2-2c41-4123-9957-f3c8a360f511"
        target="_blank" class="custom-fa-link" title="Teams">
        <img src="../../assets/images/ico-teams-00-64x64.svg" class="custom-fa-icon" alt="Custom Icon">
    </a>

    <a href="https://ngkfcs.sharepoint.com/teams/NewmarkGPT/SitePages/TopicHome.aspx?csf=1&web=1&share=Edp3B9VCVMRHi2QCtG5QsA0B90WV-dJlnD-3YdJkBP2P-Q&e=ULC37Z&cid=7bf29030-3baf-4c91-976c-1a8946d2d429"
        target="_blank" class="custom-fa-link" title="Sharepoint">
        <img src="../../assets/images/lgo-sp-line-64x64.svg" class="custom-fa-icon" alt="Custom Icon">
    </a>
    @if(isMobileDevice){
    <a id="sidebarToggle" class="custom-fa-link" href="javascript:void(0)" (click)="onToggleSidebar()">
        <i class="fa fa-bars custom-fa-icon"></i>
    </a>
    }
</div>